import BaseModel from '@/models/BaseModel.js'

export default class Order extends BaseModel {
    fields =  {
      'order_id': {
        type: 'text',
        label: 'Order',
        rules: 'required'
      },
      'action_by': {
        type: 'text',
        label: 'Status',
        rules: 'required'
      },
      'amount': {
        type: 'text',
        label: 'Pendaftar',
        rules: 'required'
      },
      'payment_method': {
        type: 'text',
        label: 'Sub Total',
        rules: 'required'
      }
    }
    endpoint = process.env.VUE_APP_API_URL + 'payment-history'
}